import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import RSVP from 'rsvp';
import { isNone } from '@ember/utils';
import moment from 'moment';

export default Route.extend({
    store: service(),
    collector: service('collector-service'),
    capi: service(),
    ttapi: service(),

    // Defining query parameters and specifying that the model should refresh when they change
    queryParams: {
        startdate: { refreshModel: true },
        user: { refreshModel: true },
        page: { refreshModel: true },
        group: { refreshModel: true },
        employer: { refreshModel: true },
        superior: { refreshModel: true },
        worktimegroup: { refreshModel: true },
    },

    // The main model hook that fetches data based on the route's parameters
    model(params) {
        const c = get(this, 'collector');

        // Building user parameters based on query parameters
        const userParams = { offset: params.page === 1 ? 0 : 25 * (params.page - 1) };
        Object.keys(params).forEach((item) => {
            if (item === 'user' && params.user) {
                // If 'user' parameter is present, set the 'id' in userParams
                userParams.id = params.user;
            } else if (c.testNeedsOne('user.' + item)) {
                // If the item is needed, add it to userParams
                userParams[item] = params[item];
            }
        });

        // Merging default parameters with user-provided parameters
        const allParams = Object.assign({ limit: 25, order: 'lastname' }, userParams);

        // Constructing the query string for the API request
        let queryString = '';
        Object.entries(allParams).forEach((param) => {
            if (isNone(param[1])) return;

            if (!queryString) queryString += `?${param[0]}=${param[1]}`;
            else queryString += `&${param[0]}=${param[1]}`;
        });

        // Checking user permissions to determine which calendar to show
        const canSeeOnlyOwnAbsences = this.collector.testNeeds(['abcense.user@hide']);
        const showTeamCalendar =
            canSeeOnlyOwnAbsences && this.collector.testNeeds(['products.team_calendar']);

        // If the user can only see their own absences but has access to the team calendar
        if (showTeamCalendar) {
            return RSVP.hash({
                showTeamCalendar: true,
                data: this.fetchTeamAbsences(params),
            });
        }

        // Fetching users from the API
        let userPromise = this.capi.request('abcenses/user' + queryString);

        // Returning a hash of promises including users and their absences
        return RSVP.hash({
            showTeamCalendar: false,
            users: userPromise,
            absences: this.fetchAbsences(userPromise, params),
        });
    },

    // Method to fetch team absences from TTapi
    fetchTeamAbsences(params) {
        return this.ttapi.request(`absence/team?startdate_enddate=${params.startdate}`);
    },

    // Method to fetch absences for users
    fetchAbsences(usersPromise, params) {
        // Using a named function 'processUsers' instead of an anonymous function
        // Binding 'this' and passing 'params' to maintain context and access to route properties
        return usersPromise.then(this.processUsers.bind(this, params));
    },

    // New method added to process users and fetch their absences
    processUsers(params, users) {
        let absencesPromises = [];
        let usersArray = users.user;

        // Parsing the date range from the query parameters
        let dateRange = params.startdate.split('_');
        let startDate = moment(dateRange[0]);
        let endDate = moment(dateRange[1]);

        // For each user, create promises to fetch their absences in 3-month intervals
        usersArray.forEach((user) => {
            let currentStart = startDate.clone();

            while (currentStart.isBefore(endDate)) {
                // Determine the end date for the current interval (max 3 months)
                let currentEnd = moment.min(currentStart.clone().add(3, 'months'), endDate);

                // Set up the parameters for the absence query
                let absenceParams = {
                    user: user.id,
                    startdate_enddate: currentStart.format('YYYY-MM-DD') + '_' + currentEnd.format('YYYY-MM-DD'),
                    order: 'startdate',
                    sideload: true,
                };

                // Add the promise to the array of promises
                absencesPromises.push(
                    get(this, 'store').query('abcense', absenceParams)
                );

                // Move the current start date to the day after the current end date
                currentStart = currentEnd.clone().add(1, 'day');
            }
        });

        // Wait for all absence promises to resolve and then process the results
        return RSVP.all(absencesPromises).then(this.flattenAbsences.bind(this));
    },

    // Use flatMap to flatten the absences arrays in a single array
    flattenAbsences(absencesArrays) {
        // Map each RecordArray to a plain array and flatten the result
        return absencesArrays.flatMap(absences => absences.toArray());
    },

});
